// 上海舱单(不知道业务逻辑，先把对应的axios留着，接口调通之后再删除axios4,axios5)
import { request } from "@/utils/request.js";
import { axiosEnum } from "./enum";

const FEST_URL = axiosEnum[process.env.VUE_APP_API_ENV];

// 申请开通业务
export function businessApply(param) {
    return request("/fcw/businessApply/shManifest/save", "post", param, {
        baseURL: FEST_URL,
    });
}

