<template>
  <div>
    <el-button type="text" @click="dialogVisible = true">
      《舱单数据发送服务协议》
    </el-button>
    <el-dialog width="70%" :visible.sync="dialogVisible">
      <div v-html="content" class="content_html"></div>
      <!--                <DialogFooter-->
      <!--                    confirmText="同意"-->
      <!--                    cancelText="关闭"-->
      <!--                    @confirm="agreeClick"-->
      <!--                    @cancel="dialogVisible = false"-->
      <!--                />-->
    </el-dialog>
  </div>
</template>

<script>
import DialogFooter from "@/components/layout/DialogFooter.vue";
import RocketHeaderDilaog from "@/components/layout/RocketHeaderDilaog.vue";
import {getNoticeById} from "@/api/sysMsg";

export default {
  props: {},
  components: {DialogFooter, RocketHeaderDilaog},
  data() {
    return {
      content: '',
      dialogVisible: false,
      checked: false,
      dialogHeader: require("@/assets/img/dialogHeader.png"),
    };
  },
  mounted() {
    this.getNoticeMsg();
  },
  methods: {
    getNoticeMsg() {
      getNoticeById({id: 81}).then((res) => {
        if (res.data.code === 200 && res.data.data.status === 'T') {
          this.content = res.data.data.contents;
        }
      })
    },
    agreeClick() {
      this.dialogVisible = false;
      this.checked = true;
      this.$emit("dialogChecked", this.checked);
      console.log(this.checked);
    },
    //   handleClose(done) {
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done();
    //       })
    //       .catch(_ => {});
    //   }
  },
};
</script>
<style scoped lang="stylus">
.header-title {
  text-align: center;
  font-size: 32px;
  position: absolute;
  top: 60px;
  right: 20px;
  color: #fff;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.text {
  text-indent: 2em;
}

.line {
  line-height: 24px;
  margin: 4px 0;

  span {
    margin: 0 4px;
    color: #b87100;
  }
}

.content_html {
  padding:0 2rem;
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
